.portPic {
  width: 20vh;
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 27.3%;
  border-radius: 55%;
}

.brand-state,
.skillList {
  font-size: 1.1rem;
  line-height: 1.8;
  color: rgb(214, 214, 214);
  font-family: "Raleway", sans-serif;
  padding: 20px;
  border-radius: 10px;
  box-shadow: -4px -4px 14px rgba(53, 53, 53, 0.749),
    4px 4px 14px rgb(41, 41, 41);
  background-color: rgba(15, 15, 15, 0.502);
  backdrop-filter: blur(40px);
  text-align: center;
  padding: 20px;
}

.aboutSpan {
  font-size: 3vh;
  color: rgb(201, 145, 71);
  font-family: "Raleway", sans-serif;
  border-bottom: 1px solid #9e4d4d;
  margin-bottom: 10px;
}
.aboutSpan span {
  font-family: "Cinzel Decorative", cursive;
  margin-bottom: 10px;
}

.aboutCardContainer {
  position: absolute;
  width: 90vmin;
  top: 50%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  left: 50%;
  transform: translate(-50%);
}
