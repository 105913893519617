.logo {
  position: absolute;
  top: 5%;
  left: 5%;
  transform: translate(-5%, -5%);
  font-family: "Cinzel Decorative", cursive;
  color: #9e4d4d;
  font-size: 2rem;

  cursor: pointer;
}

.nav {
  justify-content: flex-end;
}
.nav-link {
  margin-left: -10px;
  margin-right: 10px;
  margin-top: 5vh;
  color: #a0a0a0;
  font-family: "Raleway", sans-serif;
  text-shadow: 2px 5px 10px #1b1b1b;
  font-size: 2vh;
  letter-spacing: 1px;
  position: sticky;
  text-decoration: none;
}
.nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background-color: #9e4d4d;
  transition: width 0.4s;
}
.nav-link:hover::after {
  width: 100%;
}
.nav-link:hover {
  color: #727171;
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .line {
    height: 1px;
    width: 45px;
    background-color: #727171;
    margin: 5px;
    border-radius: 100px;
  }

  .hamburger {
    position: absolute;
    top: 5%;
    right: 5%;
    transform: translate(-5%, -5%);
    z-index: 1000;
  }
  .navigation {
    position: fixed;
    background: radial-gradient(#943434, #1b1b1b);
    opacity: 0.9;
    height: 100vh;
    width: 100vw;
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    clip-path: circle(100px at 90% -15%);
    -webkit-clip-path: circle(100px at 90% -15%);
    transition: all 0.5s ease-out;
    pointer-events: none;
  }
  .navigation.open {
    clip-path: circle(1200px at 90% -10%);
    -webkit-clip-path: circle(1200px at 90% -10%);
    pointer-events: all;
  }
  .nav-items {
    align-items: center;
    flex-direction: column;
  }

  .nav-link {
    font-family: "Cinzel Decorative", cursive;
    font-size: 1.5rem;
  }
}
