.nameContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.preHeadText {
  font-size: 1.8vh;
  color: #a0a0a0;
  text-shadow: 0px 26px 20px rgba(0, 0, 0, 0.48);
  text-align: left;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1px;
}

.webText {
  margin: -4vh -1vh -2vh 0;
  line-height: 1.3;
  font-family: "Cinzel Decorative", cursive;
  font-size: 20vmin;
  letter-spacing: 5px;
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 26px 20px rgba(0, 0, 0, 0.48);
}
.subHeadText {
  font-size: 1.5vh;
  color: #a0a0a0;
  text-align: right;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1px;
  line-height: 1;
}

.homeIconList {
  justify-content: center;
  margin-top: 50px;
}

.iconList {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 5vw;
  bottom: 10vh;
}
