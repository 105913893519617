.sectionTag {
  background: -webkit-linear-gradient(#eee, rgb(160, 160, 160));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 12px 10px rgba(0, 0, 0, 0.728);
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 30%;
  font-family: "Cinzel Decorative", cursive;
  font-size: 3.5vh;
}

.cardWrapper {
  position: absolute;
  width: 95vw;
  top: 50%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  left: 50%;
  transform: translate(-50%);
}
