.contactContainer {
  text-align: center;
  display: block;
  margin-bottom: 40px;
  width: 80vw;
  height: auto;
  background-color: rgba(15, 15, 15, 0.502);
  backdrop-filter: blur(5px);
  color: rgb(214, 214, 214);
  border-radius: 10px;
  box-shadow: -4px -4px 14px rgba(26, 26, 26, 0.486),
    4px 4px 14px rgba(0, 0, 0, 0.281);
  padding: 30px;
}

.contactTextHead {
  font-size: 4vh;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 10px auto;
  font-family: "Cinzel Decorative", cursive;
  color: rgb(201, 145, 71);
  border-bottom: 1px solid #8a3838;
}

.homeIconList a {
  color: silver;
  letter-spacing: 1px;
  border: none;
  text-decoration-color: #8a3838;
  font-family: "Raleway", sans-serif;
}

.GitHubIcon,
.linkInIcon,
.mailIcon,
.resumeIcon,
.phoneIcon {
  color: #8a3838;
  margin: 10px auto;
  border-radius: 50%;
}
.GitHubIcon:hover,
.linkInIcon:hover,
.mailIcon:hover,
.resumeIcon:hover,
.phoneIcon:hover {
  cursor: pointer;
  color: rgb(201, 145, 71);
  transform: scale(1.2);
  transition: ease-in-out;
  transition-duration: 0.2s;
  text-decoration-line: underline;
}
